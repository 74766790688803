import { useState } from "react";
import "./App.css";
import { auth, db } from "./config/firebase";

import { Auth } from "./components/Auth";
import { Dashboard } from "./components/Dashboard";

function App() {
  const [email, setEmail] = useState(localStorage.getItem("email"));

  auth.onAuthStateChanged((currentUser) => {
    if (currentUser) {
      setEmail(currentUser?.email);
      localStorage.setItem("email", currentUser?.email);
    } else {
      setEmail(null);
    }
  });

  return (
    <div className="App">
      {!email && !auth.currentUser ? (
        <div>
          <Auth />
        </div>
      ) : (
        <div>
          <Dashboard auth={auth} db={db} email={email} />
        </div>
      )}
    </div>
  );
}

export default App;
