import { useState, useRef, useEffect } from "react";
import "./style.css";

import { addDoc, collection } from "firebase/firestore";

export const Bookmark = ({ name, url, email, db }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [urlName, setUrlName] = useState("");
  const [urlLink, setUrlLink] = useState("");

  let addFormRef = useRef();

  useEffect(() => {
    if (window.innerWidth < 1200) {
      setIsModalOpen(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setUrlLink("");
        setUrlName("");
        setIsModalOpen(false);
      }
    };

    window.addEventListener("keydown", close);

    return () => {
      window.removeEventListener("keydown", close);
    };
  }, []);

  const addBookmarkWithModal = () => {
    setIsModalOpen(true);
  };
  const addToDbFromModal = async () => {
    const newUrl = urlLink.replace(/^(https|http):\/\//g, "");
    if (urlName && urlLink) {
      await addDoc(collection(db, "bookmarks"), {
        Name: urlName,
        URL: newUrl,
        profile: email,
      });
    }
    setIsModalOpen(false);
    setUrlLink(null);
    setUrlName(null);
  };

  const addBookmark = async () => {
    const newName = prompt("Enter a Name");
    if (newName != null) {
      if (newName === "") {
        alert("Please enter a Name!");
        return;
      }
      console.log(name);
      let newUrl = prompt("Enter the URL");
      if (newUrl != null) {
        if (newUrl === "") {
          alert("Please enter a URL!");
          return;
        }
        newUrl = newUrl.replace(/^(https|http):\/\//g, "");
        await addDoc(collection(db, "bookmarks"), {
          Name: newName,
          URL: newUrl,
          profile: email,
        });
      }
    }
  };

  return (
    <>
      {url ? (
        <a className="link" href={"https://" + url}>
          <div className="bookmark">
            <div className="letter-heading">
              {name.substring(0, 1).toLowerCase()}
            </div>
            <div className="name">
              {name.substring(0, 1).toUpperCase() +
                name.substring(1, name.length)}
            </div>
          </div>
        </a>
      ) : (
        <>
          <button
            className="link-add"
            onClick={() => {
              window.innerWidth < 1200 ? addBookmark() : addBookmarkWithModal();
            }}
          >
            <div className="bookmark">
              <div className="letter-heading">+</div>
              <div className="name">Add</div>
            </div>
          </button>
          <div
            className="add-modal"
            style={{
              display: isModalOpen ? "flex" : "none",
            }}
            onClick={() => {
              setUrlLink("");
              setUrlName("");
              setIsModalOpen(false);
            }}
          >
            <div
              className="add-form"
              ref={addFormRef}
              onClick={(e) => e.stopPropagation()}
            >
              <h1>Add Bookmark</h1>
              <div>
                <input
                  type="text"
                  placeholder="Enter the Name of the Website"
                  value={urlName}
                  onChange={(e) => setUrlName(e.target.value)}
                  style={{
                    textTransform: "capitalize",
                  }}
                ></input>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Enter the URL of the Website"
                  value={urlLink}
                  onChange={(e) => setUrlLink(e.target.value)}
                ></input>
              </div>
              <button
                className="add-bookmark-button"
                onClick={() => addToDbFromModal()}
              >
                Confirm Add Bookmark
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
