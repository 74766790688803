import { auth, googleProvider } from "../../config/firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

export const Auth = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [showInputs, setShowInputs] = useState(false);

  const signInGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error(error);
    }
  };

  const signIn = async () => {
    if (showInputs) {
      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        console.error(error);
      }
    } else {
      setShowInputs(true);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1 className="header">Login to Dashboard</h1>
        <div
          className="input-container"
          style={{
            opacity: showInputs ? "1" : "0",
            visibility: showInputs ? "visible" : "hidden",
            height: showInputs ? "150px" : "0px",
          }}
        >
          <input
            className="email-input"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <input
            className="password-input"
            placeholder="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <div className="buttons-container">
          <button className="submit-button" onClick={signIn}>
            <span className="icon-container">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            Sign in with Email
          </button>
          <button className="google-submit-button" onClick={signInGoogle}>
            <span className="icon-container">
              <FontAwesomeIcon icon={faGoogle} />
            </span>
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
};
