/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.css";

export const Races = ({ name, date, location }) => {
  const dateOptions = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };

  const onlyYear = {
    year: "numeric",
  };

  const [f1TVLink, setF1TVLink] = useState();

  const getRaceLink = async (year, name, loc) => {
    const raceJSON = {
      year,
      name,
      loc,
    };
    const currentRaceCacheLink = localStorage.getItem(name);
    if (!currentRaceCacheLink) {
      const rawResponse = await fetch(
        process.env.REACT_APP_FIREBASE_API_URL + "/race",
        // "http://localhost:4000/race",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(raceJSON),
        }
      );
      const respose = await rawResponse.json();
      localStorage.setItem(name, respose.link);
      return respose.link;
    } else {
      return currentRaceCacheLink;
    }
  };

  useEffect(() => {
    const getLink = async () => {
      const response = await getRaceLink(
        date.toLocaleString("en-au", onlyYear),
        name,
        location
      );
      setF1TVLink(response);
    };
    getLink();
  }, []);

  return (
    <div className="Races">
      <a className="race-link" href={f1TVLink || "#"}>
        <div className="race-title">{name}</div>
      </a>
      <div className="race-time">
        {date.toLocaleString("en-au", dateOptions)}
      </div>
    </div>
  );
};
