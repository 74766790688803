/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import "./style.css";

import { signOut } from "firebase/auth";
import {
  collection,
  query,
  where,
  onSnapshot,
  deleteDoc,
  doc,
} from "firebase/firestore";

import { Bookmark } from "./Bookmark";
import { Races } from "./Races";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

export const Dashboard = ({ db, auth, email }) => {
  const [bookmarks, setBookmarks] = useState(
    JSON.parse(localStorage.getItem("bookmarks"))
  );
  const [races, setRaces] = useState([]);
  const [wallpaperMode, setWallpaperMode] = useState(false);

  const [timeNow, setTimeNow] = useState(Date());

  let dashRef = useRef(null);

  const bookmarksRef = query(
    collection(db, "bookmarks"),
    where("profile", "==", email)
  );

  const openWallpaper = () => {
    if (!wallpaperMode) {
      dashRef.style.display = "none";
    } else {
      dashRef.style.display = "block";
    }

    setWallpaperMode(!wallpaperMode);
  };

  useEffect(() => {
    const getBookmarksListForUser = async () => {
      try {
        onSnapshot(bookmarksRef, (snapshot) => {
          const dataMap = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          if (email !== auth?.currentUser?.email) {
            localStorage.removeItem("email");
            localStorage.removeItem("bookmarks");
            signOut(auth);
          } else {
            setBookmarks(dataMap);
            localStorage.setItem("bookmarks", JSON.stringify(dataMap));
          }
        });
      } catch (error) {
        console.error(error);
      }
    };
    // Blank Records :)
    // const blankRef = query(
    //   collection(db, "bookmarks"),
    //   where("Name", "==", "")
    // );
    // onSnapshot(blankRef, (snapshot) => {
    //   console.log(snapshot.docs.length);
    //   snapshot.docs.forEach((docs) => {
    //     // console.log(doc.id);
    //     // deleteDoc(doc(db, "bookmarks", docs.id));
    //   });
    // });
    getBookmarksListForUser();
  }, []);

  useEffect(() => {
    const getRaces = async () => {
      let racesData;
      const currYear = new Date().getFullYear().toString();
      if (
        !localStorage.getItem("currentRaceSchedule") ||
        localStorage.getItem("currentRaceSeason") !== currYear
      ) {
        const response = await fetch("https://ergast.com/api/f1/current.json");
        const data = await response.json();
        racesData = data.MRData.RaceTable.Races.map((race) => ({
          id: race.round,
          name: race.raceName,
          location: race.Circuit.Location.country,
          date: new Date(`${race.date}T${race.time}`),
        }));
        localStorage.setItem("currentRaceSchedule", JSON.stringify(racesData));
        localStorage.setItem("currentRaceSeason", data.MRData.RaceTable.season);
      } else {
        racesData = JSON.parse(localStorage.getItem("currentRaceSchedule"));
      }
      if (racesData.length > 0) {
        const today = new Date();
        racesData = racesData
          .filter(
            (race) =>
              new Date(race.date) >= today ||
              new Date(race.date).toDateString() === today.toDateString()
          )
          .slice(0, 3);
      }
      setRaces(racesData);
      const cachedRaces = JSON.parse(localStorage.getItem("races"));
      if (cachedRaces) {
        cachedRaces.forEach((race, index) => {
          const foundData = racesData.find((o) => o.name === race.name);
          if (!foundData) {
            localStorage.removeItem(race.name);
          }
        });
      }
      localStorage.setItem("races", JSON.stringify(racesData));
    };
    setInterval(() => setTimeNow(Date()), 1000);
    getRaces();
  }, []);

  return (
    <div className="container">
      <div ref={(el) => (dashRef = el)}>
        <div className="logged-info-mobile">
          Logged in as {email.substring(0, email.search("@"))}.
          <button
            className="log-out"
            onClick={() => {
              localStorage.removeItem("email");
              localStorage.removeItem("bookmarks");
              signOut(auth);
            }}
          >
            Sign Out
          </button>
        </div>
        <div className="Dash">
          <div className="bookmarks">
            {bookmarks?.length > 0 ? (
              bookmarks.map((bookmark, index) => {
                return (
                  <Bookmark
                    key={index}
                    name={bookmark.Name}
                    url={bookmark.URL}
                  />
                );
              })
            ) : (
              <div></div>
            )}
            <Bookmark name={"+"} url={null} email={email} db={db} />
          </div>
          <div className="right">
            <div className="logged-info">
              Logged in as {email.substring(0, email.search("@"))}.
              <button
                className="log-out"
                onClick={() => {
                  localStorage.removeItem("email");
                  localStorage.removeItem("bookmarks");
                  signOut(auth);
                }}
              >
                Sign Out
              </button>
            </div>
            <div className="races">
              {races.map((race, index) => {
                return (
                  <Races
                    key={index}
                    name={race.name}
                    date={new Date(race.date)}
                    location={race.location}
                  />
                );
              })}
            </div>
            <div className="devices">No Devices Added!</div>
          </div>
        </div>
      </div>
      <div
        className="wallpaper-toggle"
        onClick={openWallpaper}
        style={{
          // top: wallpaperMode ? "-50px" : "",
          bottom: !wallpaperMode ? "-50px" : "calc(100% - 50px)",
          paddingTop: !wallpaperMode ? "15px" : "45px",
        }}
      >
        {!wallpaperMode ? (
          <FontAwesomeIcon icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </div>
      <div className="wallpaper-container">
        <img
          alt="Wallpaper"
          src="https://storage.googleapis.com/wtf1-com.appspot.com/1/IMG_6516.JPG.jpg"
          style={{
            top: wallpaperMode ? 0 : "100vh",
            height: wallpaperMode ? "100vh" : "0px",
          }}
          className="wallpaper-bkg"
        ></img>
        <div
          className="wallpaper-gradient"
          style={{
            top: wallpaperMode ? 0 : "100vh",
            height: wallpaperMode ? "100vh" : "0px",
          }}
        ></div>
        <div
          className="clock"
          style={{
            fontSize: wallpaperMode ? "1em" : "0px",
          }}
        >
          <div
            className="clock-left"
            style={{
              border: !wallpaperMode ? "0" : "",
            }}
          >
            <div className="year">{timeNow.split(" ")[3]}</div>
            <div className="day">
              {timeNow.split(" ")[0]}, {timeNow.split(" ")[2]}{" "}
              {timeNow.split(" ")[1]}
            </div>
          </div>
          <div className="hour">
            <div className="hour">{timeNow.split(" ")[4].split(":")[0]}</div>
          </div>
          <div className="clock-right">
            <div className="minute">{timeNow.split(" ")[4].split(":")[1]}</div>
            <div className="second">{timeNow.split(" ")[4].split(":")[2]}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
